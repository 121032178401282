<template>

  <div class="col-md-12 text-center mt-4">
    
    <div class="bg-white text-center p-4">
      <h2 class="texts">Find New and Used Construction Equipment</h2>
      <b-input type="text" class=" w-50 searchDiv bg-light" v-model="form.search" placeholder="Search..."></b-input
      ><br />
      <b-form-select class="width" :options="pTypes" v-model="ptype"></b-form-select
      ><b-form-select inline class=" margins width" v-model="form.location" :options="locations"></b-form-select
      >
      <b-form-checkbox inline class="margins" v-if="ptype === 'resource'">For Sale</b-form-checkbox
      ><b-form-checkbox inline class="margins" v-if="ptype === 'resource'">For Rent</b-form-checkbox><br /><br>
      <b-button class="btn" @click="search()" variant="info">Search</b-button><br/><br/> 
    </div>
    <div class="text-center text-dark mt-4 pt-4">
      <h3 class=" mt-4 p-4">Search Equipment by Category</h3>
      <b-container>
      <b-row class="mt-2">
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(3)">
            <b-row no-gutters>
              <b-col md="6"> 
                <b-img src="../../assets/images/gallery/earthmoving.png" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Earth Moving">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(5)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/handling.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Handling Equipment">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(6)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/cranes.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Cranes">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row> 
      <b-row class="mt-2">
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(7)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/compaction.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Compaction Equipment">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(8)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/paving.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Paving Equipment">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(9)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/trucks.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Trucks &amp; Trailers">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row> 
      <b-row class="mt-2">
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(10)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/power.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Power Generation">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(11)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/general.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="General Equipment">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(12)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/agriculture.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Agricultural Equipment">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(13)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/forestry.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Forestry Equipment">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(14)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/industrial.jpg" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Industrial Equipment">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card no-body class="overflow-hidden" @click="goToCategory(15)">
            <b-row no-gutters>
              <b-col md="6">
                <b-img src="../../assets/images/gallery/other.png" fluid alt="homepage" />
              </b-col>
              <b-col md="6">
                <b-card-body title="Other Resources">
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>  
      </b-container>
    </div>
    
    <div class="text-center mt-4 pt-3">
      <h3 class="mt-4 p-4">Newest Offers on the Market</h3><br><br>
      <b-container>
        <b-row>
          <b-col v-for="prod in products.slice(0,4)" :key="prod.id" cols="12" md="3">
            <b-card
              :title="prod.name"
              img-src="@/assets/images/gallery/power.jpg"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2"
            >
              <b-card-text>{{ prod.product_type==="sale"? prod.purchase_price:prod.rental_price+"/"+prod.rental_unit}}</b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-button to="./listing/resources/all" variant="primary" class="mt-4">See More</b-button>
      </b-container>
    </div>

  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: "Landing",
    data: () => ({
    mainProps: {
          center: false,
          fluidGrow: false,
          blank: true,
          blankColor: '#bbb',
          width: 200,
          height: 200,
          class: ''
    },
        products: [],
        pTypes:[
          { text: "Resource", value: "resource"},
          { text: "Service", value: "service"},
        ],
        locations: [],
        form: {
          search: "",
          min: 0,
          max: 999999999999,
          location: 0,
          category: 0,
          score: 5
        },
        ptype: "resource"
    }),
    computed: {},
    mounted() {
        this.initComp();
    },
    methods: {
        ...mapActions(["searchItems","getResources","getLocations"]),
        initComp(){
            this.getLocations().then(res => {
                res.forEach(element => {
                   this.locations.push({ value: element.id, text: element.name }); 
                });
            });
            this.getResources({id:2}).then(res => {
                this.products = res
            });
        },
        goToCategory(id){
          this.$router.push({name: "Listing", params:{type: "resources", cat: id}})
        },
        searchIt(){
          this.searchItems(this.form).then((res) => {
            if(this.form.ptype === "resources"){
              this.listing = res.resources
            }
            else if(this.form.ptype === "services"){
              this.listing = res.services
            }
          })
        }
    }
};
</script>
<style>
.searchDiv{
  margin-left: 3.3in;
}
.form{
  margin-left: 3in;
  margin-right: 3in;
}
.texts {
  margin-top: 0.8in;
  text-align: center;
  color: black;
}
.margins {
  margin-left: 10px;
}
.font{
    font-size: 15px;
}
.button{
  margin-left: 1in;
}
.options{
  margin-left: 3.29in;
}
.width{
  width: 150px;
}
</style>